import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from '@/shared/components/hoc';
import {
  GetIsPhoneDuplciateQueryVariables,
  useGetIsPhoneDuplciateQuery
} from '@/generated/graphql-types';
import Phone from './Phone.vue';
import { Props } from './types';
import { validateNumber } from '@/shared/util';

const isPhoneDuplicateEnhancer = wrapComponent<
  Props,
  Pick<Props, 'isValid' | 'isDuplicate' | 'checkingDuplicate'>
>((props) => {
  const isValid = computed(
    () =>
      !!props.value && validateNumber(props.value) && props.value.length === 10
  );
  const variables = computed<GetIsPhoneDuplciateQueryVariables>(() => ({
    phone: `+1${props.value}`
  }));
  const enabled = computed(() => !!props.checkIsDuplicate && isValid.value);

  const { result, loading } = useGetIsPhoneDuplciateQuery(variables, {
    fetchPolicy: 'network-only',
    enabled
  });

  return computed(() => ({
    isValid: isValid.value,
    isDuplicate: result.value?.isPhoneDuplicate || false,
    checkingDuplicate: loading.value
  }));
});

export default compose(isPhoneDuplicateEnhancer)(Phone);
