import { ApolloClient, from, InMemoryCache, Observable } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import introspectionResult from '@/generated/introspection-result';
import store from '@/store';
import config from '@/config';
import { resolvers } from './resolvers';
import { GetCurrentRegionDocument } from '@/generated/graphql-types';
import { StrictTypedTypePolicies } from '@/generated/schema-type-policies';

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const batchHttpLink = new BatchHttpLink({
  uri: config.graphql,
  // Fixed LogRocket sessions - https://docs.logrocket.com/docs/troubleshooting-sessions#apollo-client
  fetch: (...args: Parameters<typeof window.fetch>) => window.fetch(...args)
});

const authLink = setContext((_, { headers }) => {
  const token = store.getters['oidcStore/oidcAccessToken'];
  const regionId = window.sessionStorage.getItem('currentRegion');

  if (!token)
    return {
      ...headers,
      'x-selectedRegion': regionId ? regionId : null
    };

  const authorization = `Bearer ${token}`;
  const authorizationHeader = authorization ? { authorization } : {};
  return {
    headers: {
      ...headers,
      ...authorizationHeader,
      'x-selectedRegion': regionId ? regionId : null
    }
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError: _, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions && err.extensions.code) {
          case 'UNAUTHENTICATED':
            return new Observable((observer) => {
              store
                .dispatch('oidcStore/authenticateOidcSilent')
                .then(() => {
                  return store.getters['oidcStore/oidcAccessToken'];
                })
                .then((access_token) => {
                  operation.setContext(() => ({
                    headers: {
                      authorization: `Bearer ${access_token}`
                    }
                  }));
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                  };

                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
                .catch((_error) => {
                  // No refresh or client token available, we force user to login
                  store.dispatch('oidcStore/authenticateOidc');
                });
            });
          case 'FORBIDDEN':
            alert('You do not have access to this page');
            break;
          case '404':
            alert('Not Found');
        }
      }
    }
  }
);

const identifiers = {
  Staff: 'staffID',
  Region: 'regionId',
  AdvisorRegion: 'AdvisorRegionId',
  Event: 'eventId',
  School: 'schoolID',
  Chapter: 'chapterId',
  Series: 'seriesID',
  Person: 'personID',
  Teen: 'personID',
  EventSubType: 'eventSubTypeId',
  EventTrack: 'id',
  EventTicket: 'EventTicketID',
  EventStaff: 'eventStaffId',
  EmailAddress: 'id',
  ServerFilterOption: 'id',
  Attendance: 'attendanceId',
  Registration: 'registrationID',
  Phone: 'id',
  Address: 'id'
};
interface GraphqlEntity {
  __typename: keyof typeof identifiers;
  [x: string]: any;
}

const typePolicies: StrictTypedTypePolicies = {
  Teen: {
    fields: {
      Attendances: { merge: false }
    }
  },
  TeenPage: {
    fields: {
      teens: { merge: false }
    }
  }
};

const cache = new InMemoryCache({
  ...introspectionResult,
  typePolicies,
  addTypename: true,
  dataIdFromObject: (object) =>
    object[identifiers[(object as GraphqlEntity).__typename]] +
    (object as GraphqlEntity).__typename
});

const client = new ApolloClient({
  link: from([authLink, errorLink, batchHttpLink]),
  cache,
  connectToDevTools: true,
  queryDeduplication: true,
  resolvers
});

cache.writeQuery({
  query: GetCurrentRegionDocument,
  data: {
    currentRegion: sessionStorage.getItem('currentRegion')
      ? Number(sessionStorage.getItem('currentRegion'))
      : 0
  }
});

export default client;
