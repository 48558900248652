import { computed } from 'vue';
import { compose } from 'vue-compose';
import PotentialAttendeesList from './PontentialAttendeesList.vue';
import { wrapComponent } from '@/shared/components/hoc';
import {
  GetTeensForAttendanceQueryVariables,
  SortType,
  useGetTeensForAttendanceQuery
} from '@/generated/graphql-types';
import { Props } from '../types';

const getPotentialAttendeesEnhancer = wrapComponent<
  Props,
  Pick<Props, 'loading' | 'potentialAttendees' | 'potentialAttendeesFilters'>
>((props) => {
  const variables = computed<GetTeensForAttendanceQueryVariables>(() => ({
    filter: {
      sortBy: {
        name: SortType.Name,
        ascending: true
      },
      attendedSeries: true,
      seriesId: props.seriesId,
      eventId: props.eventId,
      regionId: props.regionId,
      ignoreEmail: true
    },
    limit: 0,
    offset: 0
  }));

  const { loading, result } = useGetTeensForAttendanceQuery(variables, {
    fetchPolicy: 'network-only'
  });

  return computed(() => ({
    loading: loading.value,
    potentialAttendees: result.value?.teens.teens || [],
    potentialAttendeesFilters: variables.value
  }));
});

export default compose(getPotentialAttendeesEnhancer)(PotentialAttendeesList);
