import { MutateFunction } from '@vue/apollo-composable';
import { computed } from 'vue';
import {
  RemoveStaffAttendanceMutation,
  RemoveStaffAttendanceMutationVariables,
  useRemoveStaffAttendanceMutation
} from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';

export type Props = {
  removeStaffAttendance: (
    eventId: number,
    staffId: number
  ) => ReturnType<
    MutateFunction<
      RemoveStaffAttendanceMutation,
      RemoveStaffAttendanceMutationVariables
    >
  >;
  removeStaffAttendanceLoading: boolean;
};

export const removeStaffAttendanceEnhancer = wrapComponent<object, Props>(
  (_props) => {
    const { mutate: removeStaffAttendance, loading } =
      useRemoveStaffAttendanceMutation();

    return computed(() => ({
      removeStaffAttendanceLoading: loading.value,
      removeStaffAttendance: (eventId: number, staffId: number) =>
        removeStaffAttendance({ eventId, staffId })
    }));
  }
);
