import { MutateFunction } from '@vue/apollo-composable';
import { computed } from 'vue';
import {
  GetTeensForAttendanceDocument,
  GetTeensForAttendanceQuery,
  GetTeensForAttendanceQueryVariables,
  MarkAttendanceMutation,
  MarkAttendanceMutationVariables,
  useMarkAttendanceMutation
} from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';

export type Props = {
  markAttendance: (
    eventId: number,
    personId: number
  ) => ReturnType<
    MutateFunction<MarkAttendanceMutation, MarkAttendanceMutationVariables>
  >;
  markAttendanceLoading: boolean;
};
export const markAttendanceEnhancer = wrapComponent<
  { potentialAttendeesFilters: GetTeensForAttendanceQueryVariables },
  Props
>((props) => {
  const { mutate: markAttendance, loading } = useMarkAttendanceMutation();

  return computed(() => ({
    markAttendanceLoading: loading.value,
    markAttendance: (eventId: number, personId: number) =>
      markAttendance(
        { input: { eventId, teenId: personId } },
        {
          update(cache, result) {
            const data = cache.readQuery<
              GetTeensForAttendanceQuery,
              GetTeensForAttendanceQueryVariables
            >({
              query: GetTeensForAttendanceDocument,
              variables: props.potentialAttendeesFilters
            });

            if (data?.teens.teens && result.data?.addAttendance) {
              const newTeens = data.teens.teens.map((teen) => {
                if (teen.Attendances.length > 0 && teen.personID === personId) {
                  const newAttendances = teen.Attendances.filter(
                    (attendance) => attendance.eventId !== eventId
                  );
                  // @ts-expect-error result.data is checked at the top
                  newAttendances.push(result.data.addAttendance);

                  return {
                    ...teen,
                    Attendances: newAttendances
                  };
                }
                return teen;
              });

              cache.writeQuery<
                GetTeensForAttendanceQuery,
                GetTeensForAttendanceQueryVariables
              >({
                query: GetTeensForAttendanceDocument,
                variables: props.potentialAttendeesFilters,
                data: {
                  teens: {
                    ...data.teens,
                    teens: newTeens
                  }
                }
              });
            }
          }
        }
      )
  }));
});
