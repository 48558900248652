
import Vue from 'vue';
import { phoneFormat, cleanNumber, validateNumber } from '@/shared/util';
import { Data, Methods, Props } from './types';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'Phone',
  inheritAttrs: false,
  props: {
    // NOTE FOR 'type' Prop: - this way the 'type' attribute can't get overriden inside Phone component.
    type: {},
    value: {},
    placeholder: {},
    countryISO: {},
    width: { default: '100%' },
    checkIsDuplicate: {},
    isValid: {},
    isDuplicate: {},
    checkingDuplicate: {}
  },
  data() {
    return {
      localValue: this.value
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (cleanNumber(newValue) !== cleanNumber(this.localValue)) {
          this.localValue = validateNumber(cleanNumber(newValue))
            ? phoneFormat(cleanNumber(newValue))
            : cleanNumber(newValue);
        }
      }
    },
    isDuplicate: {
      handler(newValue) {
        this.$emit('isDuplicate', newValue);
      }
    },
    checkingDuplicate: {
      handler(newValue) {
        this.$emit('checkingDuplicate', newValue);
      }
    }
  },
  methods: {
    handleBlur($event) {
      const value = $event.target.value;
      this.$emit('blur', cleanNumber(value));
    },
    handleInput($event) {
      const value = $event.target.value;
      this.$emit('input', cleanNumber(value));
    }
  }
});
