import { compose } from 'vue-compose';
import Vue, { Component } from 'vue';
import LogRocket from 'logrocket';
import { User, UserSettings } from 'oidc-client';
import omit from 'lodash/omit';
import ncsyApi from '@/modules/ncsyApi';
import { decodeURLParams, getOptions, normalizeProps } from '@/shared/util';
import AdvisorRoot from './AdvisorRoot.vue';
import { Event } from './types';
import { RecordPropsDefinition } from 'vue/types/options';
import store from '@/store';
import { setCurrentRegionEnhancer } from '@/shared/enhancers/setCurrentRegionEnhancer';
import {
  markAttendanceEnhancer,
  Props as markAttendanceProps
} from '@/shared/enhancers/markAttendanceEnhancer';
import {
  removeAttendanceEnhancer,
  Props as removeAttendanceProps
} from '@/shared/enhancers/removeAttendanceEnhancer';
import {
  markStaffAttendanceEnhancer,
  Props as markStaffAttendanceProps
} from '@/shared/enhancers/markStaffAttendanceEnhancer';
import {
  removeStaffAttendanceEnhancer,
  Props as removeStaffAttendanceProps
} from '@/shared/enhancers/removeStaffAttendanceEnhancer';

interface DecodedTokenResponse {
  Active: boolean;
  RegionId: number;
  TokenResponse: UserSettings;
  Event: Event;
}

interface Data {
  isLoading: boolean;
  isError: boolean;
  token: string | null;
  eventData: DecodedTokenResponse['Event'] | null;
  eventLoading: boolean;
}

interface Methods {
  getEvent: () => Promise<any>;
}

export type Props = markAttendanceProps &
  removeAttendanceProps &
  markStaffAttendanceProps &
  removeStaffAttendanceProps & {
    queryString: string;
    event: Event;
    eventId: number;
    teen: any;
    staff: any;
    attendeesLoading: boolean;
    setCurrentRegion: (regionId: number) => void;
    error: boolean;
    userLoading: boolean;
  };

const getEvent = async (eventId: number, token: string) => {
  const result = await ncsyApi(
    'authentication/attendanceappadvisor',
    {
      token,
      eventId
    },
    'post'
  );

  return result.data;
};

const getUserEnhancer = (Component: Component) => {
  const props = omit({ ...normalizeProps(getOptions(Component).props) }, [
    'event'
  ]) as RecordPropsDefinition<Props>;
  return Vue.extend<Data, Methods, object, Props>({
    name: 'WithUser',
    props,
    data() {
      return {
        isLoading: true,
        isError: false,
        token: null,
        eventData: null,
        eventLoading: false
      };
    },
    async created() {
      this.isLoading = true;
      this.token = decodeURLParams(this.$attrs.queryString).token;
      const result: DecodedTokenResponse = await this.getEvent();
      const user = new User({ ...result.TokenResponse });
      await store.dispatch('oidcStore/storeOidcUser', user);
      LogRocket.identify('attendanceapp@ou.org', {
        name: 'Attendance App User'
      });
      await this.setCurrentRegion(result.RegionId);
      if (result.Active === false) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    methods: {
      async getEvent() {
        this.eventLoading = true;
        const result: DecodedTokenResponse = await getEvent(
          this.eventId,
          this.token || ''
        );
        this.eventData = result.Event;
        this.eventLoading = false;
        return result;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          userLoading: this.isLoading,
          error: this.isError,
          event: this.eventData,
          attendeesLoading: this.eventLoading
        },
        on: {
          refreshEvent: () => {
            this.getEvent();
          }
        }
      });
    }
  });
};

export default compose(
  setCurrentRegionEnhancer,
  getUserEnhancer,
  markAttendanceEnhancer,
  markStaffAttendanceEnhancer,
  removeAttendanceEnhancer,
  removeStaffAttendanceEnhancer
)(AdvisorRoot);
