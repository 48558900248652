import { MutateFunction } from '@vue/apollo-composable';
import { computed } from 'vue';
import {
  MarkStaffAttendanceMutation,
  MarkStaffAttendanceMutationVariables,
  useMarkStaffAttendanceMutation
} from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';

export type Props = {
  markStaffAttendance: (
    eventId: number,
    staffId: number
  ) => ReturnType<
    MutateFunction<
      MarkStaffAttendanceMutation,
      MarkStaffAttendanceMutationVariables
    >
  >;
  markStaffAttendanceLoading: boolean;
};

export const markStaffAttendanceEnhancer = wrapComponent<object, Props>(() => {
  const { mutate: markStaffAttendance, loading } =
    useMarkStaffAttendanceMutation();

  return computed(() => ({
    markStaffAttendanceLoading: loading.value,
    markStaffAttendance: (eventId: number, staffId: number) =>
      markStaffAttendance({ eventId, staffId })
  }));
});
